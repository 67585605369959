import {Modal} from "bootstrap";
import {init} from "../init";

const initAjax = () => {


    const addSpinnerToElement = (element) => {
        if(element.querySelector('.spinner') !== null){
            return;
        }
        const spinner = `<span class="spinner spinner-border spinner-border-sm"></span>`;
        element.insertAdjacentHTML('beforeend', spinner);
    }

    const resetSpinner = (element) => {
        element.querySelectorAll('.spinner.spinner-border.spinner-border-sm').forEach(spinner => {
            spinner.remove();
        });
    }

    const links = document.querySelectorAll('a.ajax:not(.initialised)');

    links.forEach(element =>{
       element.addEventListener('click', e => {
           e.preventDefault();

           if (element && "spinner" in element.dataset) {
               addSpinnerToElement(element);
           }

           ajaxGet({
               url: element.getAttribute('href'),
               customCallback: (response) => {
                   if (element && "spinner" in element.dataset) {
                       resetSpinner(element);
                   }
               }
           });

       })
        element.classList.add('initialised');
    });

    const modalLinks = document.querySelectorAll('a.ajax-modal:not(.initialised)');

    modalLinks.forEach(element =>{
        element.addEventListener('click', e => {
            e.preventDefault();
            ajaxGet({ url: element.getAttribute('href') });

            const openModalSelector = element.getAttribute('data-open-modal');
            if (openModalSelector) {
                const modalElement = document.querySelector(openModalSelector);
                if (modalElement) {
                    const modal = new Modal(modalElement)
                    modal.show()
                }
            }
        })
        element.classList.add('initialised');
    });


    const forms = document.querySelectorAll('form.ajax:not(.initialised)');

    forms.forEach(element => {

        const submitButton = element.querySelector('[type="submit"]');


        element.addEventListener('submit', e => {
            e.preventDefault();

            if (submitButton && "spinner" in submitButton.dataset) {
                addSpinnerToElement(submitButton);
            }

            const form = element;
            const formData = new FormData(form);
            const url = form.getAttribute('action') ? form.getAttribute('action') : window.location.href;

            ajaxPost({
                url: url,
                body: formData,
                customCallback: (response) => {
                    if (submitButton && "spinner" in submitButton.dataset) {
                        resetSpinner(submitButton);
                    }
                }
            });

            if (form.classList.contains('ajax-modal-close')) {
                const modalElement = form.closest('.modal');
                if (modalElement) {
                    const modal = Modal.getInstance(modalElement) || new Modal(modalElement);
                    modal.hide()
                }
            }
        })
        element.classList.add('initialised');
    });


}

const ajaxSuccessCallback = async function (response){
    if (response.redrawSnippet) {
        response.redrawSnippet.forEach(value => {
            const element = document.getElementById(value.snippetId);
            if (element) {
                element.innerHTML = value.body;
            }
        });
    }

    if (response.appendSnippet) {
        response.appendSnippet.forEach(value => {
            const element = document.getElementById(value.snippetId);
            if (element) {
                element.insertAdjacentHTML('beforeend', value.body);
            }
        });
    }

    if (response.removeSnippet) {
        response.removeSnippet.forEach(value => {
            const element = document.getElementById(value.snippetId);
            if (element) {
                element.remove();
            }
        });
    }

    if (response.inputValues) {
        for (const [inputId, value] of Object.entries(response.inputValues)) {
            const inputElement = document.getElementById(inputId);
            if (inputElement) {
                inputElement.value = value;
            }
        }
    }

    if (response.urlQuery) {
        const url = new URL(window.location.href);
        Object.entries(response.urlQuery).forEach(([key, value]) => {
            if (value === null) {
                url.searchParams.delete(key);
            } else {
                url.searchParams.set(key, value);
            }
        });
        window.history.replaceState({}, '', url);
    }

    if (response.openModalId) {
        const modalElement = document.getElementById(response.openModalId);
        if (modalElement) {
            const modal = new Modal(modalElement)
            modal.show()
        }
    }

    if (response.closeModalId) {
        const modalElement = document.getElementById(response.closeModalId);
        if (modalElement) {
            const modal = Modal.getInstance(modalElement) || new Modal(modalElement);
            modal.hide()
        }
    }

    if (response.redirectTo) {
        window.location.href = response.redirectTo;
    }

    await init()
}

export const ajaxGet = ({url, customCallback = null, processSuccessCallback = true}) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true); // Open a GET request
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {

            const response = JSON.parse(xhr.responseText);

            if(customCallback !== null){
                customCallback(response)
            }

            if(processSuccessCallback){
                ajaxSuccessCallback(response);
            }
        } else {
            // Handle HTTP errors (e.g., 404, 500, etc.)
            alert('Error: ' + xhr.status + ': ' + xhr.statusText);
        }
    };

    xhr.onerror = function() {
        // Handle network errors
        alert('Network Error');
    };

    xhr.send(); // Send the request

}
const ajaxPost = ({url, body, customCallback = null}) => {


    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true); // Open a POST request
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
            const response = JSON.parse(xhr.responseText);

            if(customCallback !== null){
                customCallback(response)
            }

            ajaxSuccessCallback(response);
        } else {
            // Handle HTTP errors
            console.log({
                message: 'Error: ' + xhr.status + ': ' + xhr.statusText,
                type: 'error'
            });
        }
    };

    xhr.onerror = function() {
        // Handle network errors
        console.log({
            message: 'Network Error',
            type: 'error'
        });
    };

    xhr.send(body); // Send the request with the body

}



export default initAjax;