import 'bootstrap'
import { initOnce, init } from './init'

document.addEventListener('DOMContentLoaded', () => {
    initOnce()
    init()



    const navbar = document.getElementById("navbar");
    const hamburger = document.querySelector(".hamburger");

    if (navbar && hamburger) {
        hamburger.addEventListener("mouseenter", () => {
            navbar.classList.add("show");
        });

        navbar.addEventListener("mouseleave", () => {
            navbar.classList.remove("show");
        });
    }
})