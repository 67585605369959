const initImageLazyLoad = (observer) => {

    if(observer){
        observer.disconnect();
    }

    const elements = document.querySelectorAll('img[data-src]');

    const observerOptions = {
        rootMargin: '0px 0px 250px 0px', // preload images 300px before they appear
        threshold: 0
    };

    const onIntersection = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                img.src = img.getAttribute('data-src');
                img.removeAttribute('data-src');
                observer.unobserve(img);
            }
        });
    };

    observer = new IntersectionObserver(onIntersection, observerOptions);

    elements.forEach(element => {
        observer.observe(element);
    });

    return observer;
}

export default initImageLazyLoad;