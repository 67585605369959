import initAjax from './shared/ajax'
import initImageLazyLoad from './components/image-lazy-load'
let imageObserver;
export const init = async () => {
    initAjax()

    await checkElementAndImportInternModule('.navbar-collapse', './components/hamburger-menu');
    await checkElementAndImportInternModule('.splide', './components/carousel');
    await checkElementAndImportInternModule('.flash-message', './components/flash-messages');
    await checkElementAndImportInternModule('#age_verify_modal', './components/age-verify-modal');
    await checkElementAndImportInternModule('.reply-btn', './components/comments');
    await checkElementAndImportInternModule('.ansp-button', './shared/antispam');
    await checkElementAndImportInternModule('.show-more-images', './shared/show-more-images');
    await checkElementAndImportInternModule('.copy-clipboard', './shared/clipboard-copy');
    await checkElementAndImportInternModule('img[data-thumbnails]', './components/image-thumbnails');
    await checkElementAndImportInternModule('.generated-mini-avatar', './components/mini-avatar');
    imageObserver = await initImageLazyLoad(imageObserver);

}

export const initOnce = async () => {
    await checkElementAndImportInternModule('.video-player', './components/video-player');
    await checkElementAndImportInternModule('.search-whisperer', './components/search-whisperer');
    await checkElementAndImportInternModule('.download-dropdown', './components/download-video-button');

    if (document.querySelector('.gallery-popup')) {
        await import('fslightbox/index')
    }

}

const checkElementAndImportInternModule = async (element, modulePath) => {
    if (document.querySelector(element)) {
        import(`${modulePath}`).then(module => module.default());
    }
}